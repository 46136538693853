import { postAuthAPI } from "../helper";
import {postSecureAPI} from "../helper"

export const login = async (obj) => {
  const result = await postAuthAPI("/auth/signin", obj);
  return result;
};

export const updateuserdata = async (payload) => {
  const result = await postAuthAPI("/auth/updateuserdata", payload);
  return result;
};


export const getSubscriber = async (mobile) => {
  const payload = { mobile };
  const result = await postSecureAPI("getsubs", payload);
  return result;
};
