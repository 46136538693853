import { postHostelAPI } from "../helper";

// const parentsubsid = sessionStorage.getItem("parentsubsid");

export const allbiz = async () => {
  const parentsubsid ={
    parentsubsid: "184bf54b8edc4df996080fadaad498ea"
}
  const result = await postHostelAPI("/allbiz", parentsubsid);
  // , payload);
  return result;
};
export const allbizdd = async () => {
  const parentsubsid ={
    parentsubsid: "184bf54b8edc4df996080fadaad498ea"
}
  const result = await postHostelAPI("/allbizdd", parentsubsid);
  // , payload);
  return result;
};

export const addbiz = async (data) => {
  const parentsubsid =
     "184bf54b8edc4df996080fadaad498ea"

  const payload = { ...data,parentsubsid };
  const result = await postHostelAPI("/addbiz", payload);
  // , payload);
  return result;
};
