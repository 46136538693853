import { postHostelAPI } from "../helper";


export const allocatebed = async (data) => {
    const parentsubsid =
       "184bf54b8edc4df996080fadaad498ea"
  
    const payload = { ...data,parentsubsid };
    const result = await postHostelAPI("/updatebedallocation", payload);
    // , payload);
    return result;
  };