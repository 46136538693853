import { createStore, combineReducers } from "redux";
import { authReducer } from "./Reducer/authReducer";
import { subidReducer } from "./Reducer/subidReducer";
import { roomReducer } from "./Reducer/roomReducer";
import { hostelReducer } from "./Reducer/hostelReducer";
import { roombybedReducer } from "./Reducer/roombybedReducer";

export const configStore = () => {
  const mystore = createStore(
    combineReducers({
      authReducer,
      subidReducer,
      roomReducer,
      hostelReducer,
      roombybedReducer,
    }),
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  );
  return mystore;
};
