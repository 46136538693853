import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { login, getSubscriber } from "../service/auth/login";
import { useDispatch } from "react-redux";
import { enumReducerType } from "../config/enumReducerTypes";
import { useFormik } from "formik";
import * as Yup from "yup";
import { allbiz } from "../service/HostelBiz/allbiz";
import {
  Alert,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  OutlinedInput,
  IconButton,
  InputAdornment,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

export const Login = () => {
  const [msg, setMsg] = useState("");
  const [msgErr, setMsgErr] = useState("");
  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  var mobileRegx = /^\d{10}$/;
  const validationSchema = Yup.object({
    mobile: Yup.string()
      .required("Required *")
      .matches(mobileRegx, "Mobile Number should be 10 digits !!!!"),
    password: Yup.string()
      .required("Required *")
      .min(5, "Password must be at least 5 charaters"),
  });

  const formik = useFormik({
    initialValues: {
      mobile: "7058657111",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        const payload = await login(values);
        sessionStorage.setItem("token", payload.accessToken);
        dispatch({ type: enumReducerType.SET_USER_DATA, payload });
        const result = await getSubscriber(formik.values.mobile);
        console.log("======Subs----->", result);
        dispatch({ type: enumReducerType.SUBS_DATA, payload: result });
        sessionStorage.setItem("parentsubsid", result.subsid);
        const bizdata = await allbiz();
        dispatch({ type: enumReducerType.BIZ_DATA, payload: bizdata });
        console.log("BIZDATA----->", bizdata);

        navigate("/");
        setMsgErr("");
        resetForm({ values: "" });
      } catch (e) {
        setMsgErr(e.response.data.message);
        setMsg("");
      }
    },
  });

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    const result = sessionStorage.getItem("parentsubsid");
    const type = enumReducerType.SUBID_DATA;
    const payload = result;
    const action = { type, payload };
    dispatch(action);
  }, []);

  return (
    <div className="content-align-outside-comp">
      <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
        <Grid container spacing={3}>
          <Grid
            item
            xl={8}
            lg={8}
            md={10}
            sm={12}
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src="https://s3.us-west-2.amazonaws.com/www.bookingninjas.com/img/illustration-8.svg"
              alt=""
              style={{ width: "70%",marginTop:'50px' }}
            />
          </Grid>
          <Grid item xl={3} lg={3} md={10} sm={12} xs={12}>
            <Card
              sx={{
                height: "380px",
                marginTop: "15%",
                boxShadow: " 0px 0px 10px rgba(0, 0, 0, 0.3) ",
              }}
            >
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xl={4} lg={12} md={10} sm={12} xs={12}>
                    <Grid container>
                      <Grid item xs={8}>
                        <span style={{ fontSize: "40px" }}>Login</span>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      required
                      id="mobile"
                      name="mobile"
                      label="Mobile Number"
                      value={formik.values.mobile}
                      onChange={formik.handleChange}
                      autoComplete="off"
                      error={
                        formik.touched.mobile && Boolean(formik.errors.mobile)
                      }
                      helperText={formik.touched.mobile && formik.errors.mobile}
                    />
                  </Grid>
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel
                        required
                        htmlFor="outlined-adornment-password"
                      >
                        Password
                      </InputLabel>
                      <OutlinedInput
                        type={values.showPassword ? "text" : "password"}
                        required
                        id="password"
                        name="password"
                        label="Password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.password &&
                          Boolean(formik.errors.password)
                        }
                        helperText={
                          formik.touched.password && formik.errors.password
                        }
                        autoComplete="current-password"
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {values.showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Button
                      fullWidth
                      variant="contained"
                      color="warning"
                      type="submit"
                    >
                      Login
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </form>
      <div className="addpadding10 addmargin10">
        {msg && <Alert severity="success">{msg}</Alert>}
        {msgErr && <Alert severity="error">{msgErr}</Alert>}
      </div>
    </div>
  );
};
