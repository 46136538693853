import { Tabs,Tab } from '@mui/material';
import React,{useEffect,useState} from 'react';
import { AddHostelBiz } from './AddHostelBiz';
import {AddRoom} from './AddRoom';
import { AddBed } from './AddBed';
import { useNavigate } from 'react-router-dom';
import HostelBookingConfirm from './HostelBookingConfirm';

export const AddComp = () => {
    const navigate=useNavigate()
    const [value, setValue] = useState('confirm');

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
  
    useEffect(() => {
      const tkn = sessionStorage.getItem("token");
      !tkn && navigate("/login");
    }, []);
  

    return (
        <div className='content-align-outside-comp content-height' >

       <Tabs
         value={value}
         onChange={handleChange}
         >
         <Tab label="Confirm Book"   value="confirm"/>
         <Tab label="ADD HOSTEL" value="hostel"/>
         <Tab label="ADD ROOM"   value="room"/>
         <Tab label="ADD BED"   value="bed"/>
         
</Tabs>
<br />

         {value==="hostel"&& <AddHostelBiz/>}
         {value==="room"&& <AddRoom/>}
         {value==="bed"&& <AddBed/>}
         {value==="confirm"&& <HostelBookingConfirm/>}
             </div>
    );
};
