import { postRoomAPI,postRoomAllAPI } from "../helper";

export const addbed = async (data) => {
  const parentsubsid="184bf54b8edc4df996080fadaad498ea"

    console.log('parentSubid',parentsubsid)
    const payload = {
        ...data,
    parentsubsid,
  };
  const result = await postRoomAPI("/addbed", payload);
  console.log("---result--->", result);
  return result;
};

export const bedall = async ()=>{
    const payload ={
        parentsubsid: "184bf54b8edc4df996080fadaad498ea"
    }
    const result = await postRoomAllAPI("/allbeds", payload);
    
    return result;
  }