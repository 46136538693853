import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Slide,
  TextField,
} from "@mui/material";
import { useSelector } from "react-redux";
import { allbedbyroom } from "../service";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AllocatedShow = ({ onClose, open, bedId }) => {
  console.log("bedId", bedId);
  const roomId = useSelector((state) => state.roombybedReducer.roombybed);
  const [data, setData] = useState([]);
  const [show, setShow] = useState("");

  console.log("show", show);

  const getallbed = async () => {
    const payload = {
      roomId,
    };
    const result = await allbedbyroom(payload);
    setData(result);
    console.log(result, "------------");
  };

  useEffect(() => {
    const filtered = data.find((item) => item._id === bedId);
    // setShow(filtered);
    console.log(filtered, "---------------");
  }, [bedId]);

  useEffect(() => {
    getallbed();
  }, []);

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => onClose()}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Allocate Person"}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              Name :
            </Grid>
            <Grid item xs={12}>
              <TextField type="number" fullWidth label="Payment" />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClose()}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AllocatedShow;
