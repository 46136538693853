import React, { useEffect, useState } from "react";
import Select from "react-select";
import { allbedbyroom, allocatebed, roomall } from "../service";
import { Button, Card, CardContent, Grid } from "@mui/material";
import BedSharpIcon from "@mui/icons-material/BedSharp";

const HostelBookingConfirm = () => {
  const [roomId, setRoomId] = useState("6ce155c425ee48c983129a4cc5d7f212");
  const [options, setOptions] = useState([]);
  const [data, setData] = useState([]);

  const getRoomall = async () => {
    const result = await roomall();
    setOptions(result);
  };

  const getallbed = async () => {
    const payload = {
      roomId,
    };
    const result = await allbedbyroom(payload);
    setData(result.filter((item) => item.isAllocated === true));
  };

  const handleChangedrop = (e) => {
    setRoomId(e.value);
  };

  const handleDeActive = async (isAllocated, bedId) => {
    const payload = {
      bedId,
      isAllocated: false,
    };
    if (window.confirm("Your bed will be  deleted permanently !!!")) {
      const result = await allocatebed(payload);
    }
  };

  const customStyles = {
    menu: (base) => ({
      ...base,
      zIndex: 1000,
    }),
  };
  useEffect(() => {
    getRoomall();
    getallbed();
  }, [roomId]);

  const defaultOption = options.length > 0 ? options[0] : null;

  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Select
            defaultValue={defaultOption}
            styles={customStyles}
            options={options}
            onChange={handleChangedrop}
          />
        </Grid>
        {data.length > 0 &&
          data.map((item) => (
            <Grid item xs={12}>
              <Card
                sx={{
                  marginBottom: "10px",
                  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                }}
              >
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xl={3} lg={3} md={3} sm={8} xs={8}>
                      <span style={{ fontSize: 20 }}>{item.subsAllocatId}</span>{" "}
                    </Grid>
                    <Grid item xl={2} lg={2} md={2} sm={4} xs={4}>
                      <span style={{ fontSize: 20 }}>
                        {" "}
                        <BedSharpIcon sx={{ fontSize: "20px" }} />{" "}
                        {item.bedLocation}
                      </span>{" "}
                    </Grid>

                    <Grid item xl={2} lg={2} md={2} sm={4} xs={4}>
                      {" "}
                      <span style={{ fontSize: 20 }}>
                        ₹ {item.bedCharges}
                      </span>{" "}
                    </Grid>
                    <Grid item xl={3} lg={3} md={3} sm={8} xs={8}>
                      {" "}
                      <span style={{ fontSize: 20 }}>
                        {" "}
                        {item.createdDate}
                      </span>{" "}
                    </Grid>
                    <Grid item xl={2} lg={2} md={2} sm={8} xs={8}>
                      {" "}
                      <Button
                        onClick={() =>
                          handleDeActive(item.isAllocated, item.bedId)
                        }
                        variant="contained"
                        fullWidth
                        color="warning"
                      >
                        DeActive
                      </Button>{" "}
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          ))}
      </Grid>
    </div>
  );
};

export default HostelBookingConfirm;
