import { enumReducerType } from "../../config/enumReducerTypes";
export const authReducer = (state = { userdata: [] }, action) => {
  switch (action.type) {
    case enumReducerType.SET_USER_DATA:
      return {
        ...state,
        userdata: action.payload,
      };
  }

  return state;
};
