export const enumReducerType = {
    SET_USER_DATA: "SET_USER_DATA",
    SUBS_DATA: "SUBS_DATA",
    CURR_SELECTED_SUBSCRIBER: "CURR_SELECTED_SUBSCRIBER",
    CURR_SELECTED_BIZ: "CURR_SELECTED_BIZ",
    Curr_User_Data: "Curr_User_Data",
    BIZ_DATA: "BIZ_DATA",
    STATUS_DATA: "STATUS_DATA",
    DASHBOARD_DATA: "DASHBOARD_DATA",
    RPT_DATA: "RPT_DATA",
    SUBID_DATA: "SUBID_DATA",
    ROOM_ID:"ROOM_ID",
    HOSTEL_ID:"HOSTEL_ID",
    ROOM_BY_BED:"ROOM_BY_BED"
  };
  