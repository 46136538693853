import { Button, Card, CardContent, Grid, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { addroom, allbizdd } from "../service";
import Select from "react-select";
import { AlertComp } from "./AlertComp";

export const AddRoom = () => {
  const [name, setName] = useState("");
  const [roomsize, setRoomsize] = useState("");
  const [bedCapacity, setBadcapacity] = useState("");
  const [hostelId, setHostelId] = useState("");
  const [show, setShow] = useState("");
  const [data, setData] = useState([]);

  const handleRoom = async () => {
    const payloads = {
      roomsize,
      bedCapacity,
      name,
      hostelId,
    };

    const result = await addroom(payloads);
    setShow(result.message);
    setName("");
    setRoomsize("");
    setBadcapacity("");
  };
  const handleClear = () => {
    setName("");
    setRoomsize("");
    setBadcapacity("");
  };

  const handleChangeDrop = (e) => {
    setHostelId(e.value);
  };

  const customStyles = {
    menu: (base) => ({
      ...base,
      zIndex: 1000,
    }),
  };
  const getHostel = async () => {
    const result = await allbizdd();
    setData(result);
  };

  useEffect(() => {
    getHostel();
  }, []);

  return (
    <div style={{ height: "100%", minHeight: "78vh" }}>
      <Grid container spacing={2}>
        <Grid item xl={4} lg={4} md={10} sm={12} xs={12}></Grid>
        <Grid item xl={4} lg={4} md={10} sm={12} xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <div
                    style={{
                      height: "70px",
                      backgroundColor: "#00438b",
                      color: "white",
                      margin: "-16px",
                      marginBottom: "20px",
                      justifyContent: "center",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <span style={{ fontSize: "25px" }}>Add New Room</span>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <Select
                    styles={customStyles}
                    options={data}
                    onChange={handleChangeDrop}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    value={name}
                    onChange={(e) => setName(e.target.value.toUpperCase())}
                    label="Room Name"
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    value={roomsize}
                    type="number"
                    onChange={(e) => setRoomsize(e.target.value)}
                    label="Room Size"
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    value={bedCapacity}
                    type="number"
                    onChange={(e) => setBadcapacity(e.target.value)}
                    label="Room BedCapacity"
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <Button onClick={handleRoom} variant="contained" fullWidth>
                    Submit
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    variant="outlined"
                    fullWidth
                    color="warning"
                    onClick={handleClear}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <AlertComp message={show} />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};
