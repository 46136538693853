import React, { useState } from "react";
import { Button, Card, CardContent, Grid, TextField } from "@mui/material";
import { addbiz } from "../service";
import { AlertComp } from "./AlertComp";

export const AddHostelBiz = () => {
  const [name, setName] = useState("");
  const [basecharges, setBasecharges] = useState("");
  const [show, setShow] = useState("");

  const handleHostel = async () => {
    const payload = {
      name,
      basecharges,
    };
    const result = await addbiz(payload);
    setShow(result.message);
  };
  const handleClear = () => {
    setName("");
    setBasecharges("");
  };
  return (
    <div
      style={{
        height: "100%",
        minHeight: "78vh",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xl={4} lg={4} md={10} sm={12} xs={12}></Grid>
        <Grid item xl={4} lg={4} md={10} sm={12} xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <div
                    style={{
                      height: "60px",
                      backgroundColor: "#00438b",
                      color: "white",
                      margin: "-16px",
                      marginBottom: "20px",
                      justifyContent: "center",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <span style={{ fontSize: "25px" }}>
                      Add New Hostel
                    </span>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    value={name}
                    onChange={(e) => setName(e.target.value.toUpperCase())}
                    label="Hostel Name"
                    fullWidth
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    value={basecharges}
                    onChange={(e) => setBasecharges(e.target.value)}
                    label="Basecharges"
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <Button onClick={handleHostel} variant="contained" fullWidth>
                    Submit
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    variant="outlined"
                    fullWidth
                    color="warning"
                    onClick={handleClear}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <AlertComp message={show} />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};
