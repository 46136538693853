import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./Home";
import { Login } from "./Login";
import Logout from "./Logout";
import "./common.css";
import Facilty from "./Facilty";
import { HostelBook } from "./HostelBook";
import { AddComp } from "./AddComp";

export const NavRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<Login />} />
      <Route path="/facility" element={<Facilty />} />
      <Route path="/hostelbook" element={<HostelBook />} />
      <Route path="/dashboard" element={<AddComp />} />
      <Route path="/logout" element={<Logout />} />
    </Routes>
  );
};
