import { postRoomAPI,postRoomAllAPI } from "../helper";

// const parentsubsid = sessionStorage.getItem("parentsubsid");
const parentsubsid= "184bf54b8edc4df996080fadaad498ea"
export const addroom = async (data) => {
    const payload = {
        ...data,
    parentsubsid,
  };
  const result = await postRoomAPI("/addroom", payload);
  console.log("---result--->", result);
  return result;
};

export const roomall = async ()=>{
    const payload ={ parentsubsid}
    const result = await postRoomAllAPI("/allroomsdropdown", payload);
    
    return result;
  }