import { enumReducerType } from "../../config/enumReducerTypes";

export const subidReducer = (state = { subid:'' }, action) => {
  switch (action.type) {
    case enumReducerType.SUBID_DATA:
      return {
        ...state,
        subid: action.payload,
      };
  }

  return state;
};
