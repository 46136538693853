import { Card, CardContent, Grid } from "@mui/material";
import React, { useEffect } from "react";
import { facality } from "./Data";
import { useNavigate } from "react-router-dom";

const Facilty = () => {
  return (
    <Grid container spacing={3}>
      {facality.map((item) => (
        <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
          <Card sx={{ height: 250, bgcolor: "#ebf0f573" }}>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={9}>
                  <span
                    style={{
                      fontSize: "25px",
                      fontWeight: 600,
                    }}
                  >
                    {" "}
                    {item.title}{" "}
                  </span>
                </Grid>
                <Grid item xs={3}>
                  <span style={{ color: "#00438b", zIndex: -1 }}>
                    {" "}
                    {item.icons}{" "}
                  </span>
                </Grid>
                <Grid item xs={12}>
                  <p style={{ color: "#6e7076", fontWeight: 300 }}>
                    {" "}
                    {item.disc.slice(0, 120) + "....."}{" "}
                  </p>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default Facilty;
