import { enumReducerType } from "../../config/enumReducerTypes";
export const roomReducer = (state = { roomid:'' }, action) => {
  switch (action.type) {
    case enumReducerType. ROOM_ID:
      return {
        ...state,
        roomid: action.payload,
      };
  }

  return state;
};
