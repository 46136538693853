"use client";
import axios from "axios";
import { baseUrl, baseAuthUrl, streetven, basehmurl } from "../config/common";
import { authTokenHeader } from "../config/common";
import {baseUrlauth} from "../config/common"

export const getAPI = async (requestUrl) => {
  const requrl = `${baseUrl}/${requestUrl}`;
  const result = await axios.get(requrl);
  return result.data;
};

export const getSecureAPI = async (requestUrl) => {
  const currentAuthTokenHeader = authTokenHeader();
  const requrl = `${baseUrl}/${requestUrl}`;
  const result = await axios.get(requrl, currentAuthTokenHeader);
  return result.data;
};


export const postStreetvenAPI = async (requestUrl, payload) => {
  const requrl = `${streetven}/${requestUrl}`;
  const result = await axios.post(requrl, payload);
  return result.data;
};

export const postSecureAPI = async (requestUrl, payload) => {
  const currentAuthTokenHeader = authTokenHeader();
  console.log("==currentAuthTokenHeader====>", currentAuthTokenHeader);
  const requrl = `${baseUrlauth}/${requestUrl}`;
  const result = await axios.post(requrl, payload, currentAuthTokenHeader);
  return result.data;
};
export const getSuballAPI = async (requestUrl, payload) => {
  const currentAuthTokenHeader = authTokenHeader();
  console.log("==currentAuthTokenHeader====>", currentAuthTokenHeader);
  const requrl = `${baseAuthUrl}/${requestUrl}`;
  const result = await axios.post(requrl, payload, currentAuthTokenHeader);
  return result.data;
};

export const postAuthAPI = async (requestUrl, payload) => {
  const requrl = `${baseAuthUrl}/${requestUrl}`;
  const result = await axios.post(requrl, payload);
  return result.data;
};
export const postUploadAPI = async (requestUrl, payload) => {
  const requrl = `${baseUrl}/${requestUrl}`;
  const result = await axios.post(requrl, payload);
  return result.data;
};

// Room API
export const postRoomAPI = async (requestUrl, payload) => {
  const currentAuthTokenHeader = authTokenHeader();
  console.log("==currentAuthTokenHeader====>", currentAuthTokenHeader);
  const requrl = `${basehmurl}/${requestUrl}`;
  const result = await axios.post(requrl, payload);
  return result.data;
};

export const postRoomAllAPI = async (requestUrl, payload) => {
  const requrl = `${basehmurl}/${requestUrl}`;
  const result = await axios.post(requrl, payload);
  return result.data;
  };
  export const postHostelAPI = async (requestUrl, payload) => {
    const currentAuthTokenHeader = authTokenHeader();
    console.log("==currentAuthTokenHeader s====>", currentAuthTokenHeader);
    const requrl = `${basehmurl}/${requestUrl}`;
    const result = await axios.post(requrl, payload);
    return result.data;
  };
