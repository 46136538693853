import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { Grid, TextField } from '@mui/material';
import Select from "react-select"
import { allocatebed, getallsubscriber } from '../service';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const  Allocatebed=({ open, onClose,bedId }) =>{
  const [bedCharges,setBedCharges]=useState('')
  const [subsAllocatId,setSubsAllocatId]=useState('')
  const [options,setOptions]=useState([])
  const [isAllocated]=useState(true)
  
  const handleAllocatebed= async()=>{
     const payload= {
      bedId,subsAllocatId,bedCharges,isAllocated
     }
     const result=await allocatebed(payload)
      onClose()
  }

  const getSubAll=async()=>{
    const result=await getallsubscriber()
    setOptions(result.map((item)=>({value:item.subsid,label:item.name})))
    console.log("GetAll Sub",result)
  }

   
  const customStyles = {
    menu: (base) => ({
      ...base,
      zIndex: 1000,
    }),
  };

  useEffect(()=>{
    getSubAll()
  },[])

  return (
    <div>
      <Dialog
      style={customStyles}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={()=>  onClose()}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Allocate Person"}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>

            <Grid item xs={12}>
        <Select onChange={(e)=>setSubsAllocatId(e.label)} options={options} styles={customStyles}/>
            </Grid>
            <Grid item xs={12}>
                <TextField  onChange={(e)=>setBedCharges(e.target.value)} type="number" fullWidth label="Payment"/>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAllocatebed}>Submit</Button>
          <Button onClick={()=>onClose()}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
