import { enumReducerType } from "../../config/enumReducerTypes";

export const roombybedReducer = (state =   { roombybed: "" }, action) => {
    switch (action.type) {
      case enumReducerType.ROOM_BY_BED:
        return{
          ...state,
          roombybed: action.payload,
        };
    }
  
    return state;
  };
  