import React from 'react';
import SingleBedIcon from '@mui/icons-material/SingleBed';
import LocalHotelIcon from '@mui/icons-material/LocalHotel';
import RouterIcon from '@mui/icons-material/Router';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import LocalLaundryServiceIcon from '@mui/icons-material/LocalLaundryService';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import Diversity2Icon from '@mui/icons-material/Diversity2';
import CameraOutdoorIcon from '@mui/icons-material/CameraOutdoor';
import GavelIcon from '@mui/icons-material/Gavel';
import PhoneForwardedIcon from '@mui/icons-material/PhoneForwarded';
import RamenDiningIcon from '@mui/icons-material/RamenDining';
import WaterDropOutlinedIcon from '@mui/icons-material/WaterDropOutlined';
import ElectricalServicesOutlinedIcon from '@mui/icons-material/ElectricalServicesOutlined';
import FingerprintOutlinedIcon from '@mui/icons-material/FingerprintOutlined';

export const facality=[
    {
      title:'Twin/Triple Sharing Room',
      disc:'Hostels usually provide basic bedding including sheets, blankets, and pillows. However, its common for residents to bring their own bedding for comfort.',
     icons:<SingleBedIcon sx={{fontSize:'45px'}}/>
    },
    {
      title:'Comfortable Beds',
      disc:'Hostels usually provide basic bedding including sheets, blankets, and pillows. However, its common for residents to bring their own bedding for comfort.',
      icons:<LocalHotelIcon sx={{fontSize:'45px'}}/>
    
    },
    {
      title:'High Speed WiFi',
      disc:'Most hostels provide Wi-Fi access for residents to stay connected.',
      icons:<RouterIcon sx={{fontSize:'45px'}}/>
      
    },
    {
      title:'Professional housekeeping',
      disc:'Hostel facilities have shared bathroom facilities with showers, toilets, and sinks. Some hostels offer en-suite bathrooms in private rooms.',
      icons:<CleaningServicesIcon sx={{fontSize:'45px'}}/>
    },
    {
      title:'Laundry',
      disc:'Some hostels have laundry facilities or arrangements with nearby laundromats for residents to wash their clothes.',
      icons:<LocalLaundryServiceIcon sx={{fontSize:'45px'}}/>
    },
    
    {
      title:'Common Areas',
      disc:'Hostels often have common areas where residents can socialize, relax, and work. These areas might include lounges, TV rooms, and study spaces.',
      icons:<Diversity2Icon sx={{fontSize:'45px'}}/>
    },
    {
      title:'24x7 Surveillance',
      disc:'Hostels prioritize the safety of their residents. Security measures can include CCTV cameras, secure entry points, and sometimes on-site security personnel. Many hostels provide lockers or safe storage options for residents to store their valuables.',
      icons:<CameraOutdoorIcon sx={{fontSize:'45px'}}/>
    },
    {
      title:'Rules and Regulations',
      disc:'Hostels have specific rules regarding quiet hours, guests, cleanliness, and other aspects of communal living. Residents are expected to abide by these rules to ensure a comfortable environment for everyone.',
      icons:<GavelIcon sx={{fontSize:'45px'}}/>
    },
    {
      title:'Biometric Security',
      disc:'Hostels have specific rules regarding quiet hours, guests, cleanliness, and other aspects of communal living. Residents are expected to abide by these rules to ensure a comfortable environment for everyone.',
      icons:<FingerprintOutlinedIcon sx={{fontSize:'45px'}}/>
    },
    {
      title:'24x7 Parent response line',
      disc:'Hostels have specific rules regarding quiet hours, guests, cleanliness, and other aspects of communal living. Residents are expected to abide by these rules to ensure a comfortable environment for everyone.',
      icons:<PhoneForwardedIcon sx={{fontSize:'45px'}}/>
    },
    {
      title:'Certified 3 Times meals',
      disc:'Hostels have specific rules regarding quiet hours, guests, cleanliness, and other aspects of communal living. Residents are expected to abide by these rules to ensure a comfortable environment for everyone.',
      icons:<RamenDiningIcon sx={{fontSize:'45px'}}/>
    },
    {
      title:'Clean RO Water Supply',
      disc:'Hostels have specific rules regarding quiet hours, guests, cleanliness, and other aspects of communal living. Residents are expected to abide by these rules to ensure a comfortable environment for everyone.',
      icons:<WaterDropOutlinedIcon sx={{fontSize:'45px'}}/>
    },
    {
      title:'Power Backup',
      disc:'Hostels have specific rules regarding quiet hours, guests, cleanliness, and other aspects of communal living. Residents are expected to abide by these rules to ensure a comfortable environment for everyone.',
      icons:<ElectricalServicesOutlinedIcon sx={{fontSize:'45px'}}/>
    },
    {
      title:'Student Community Interactions',
      disc:'Hostels have specific rules regarding quiet hours, guests, cleanliness, and other aspects of communal living. Residents are expected to abide by these rules to ensure a comfortable environment for everyone.',
    icons:<Diversity3Icon sx={{fontSize:'45px'}}/>
    },
]

export const hsdata = [
  {
    roomid: "ROOM A-1",
    active: false,
    rooms: [
      {
        bedid: 10001,
        active: false,
      },
      {
        bedid: 10002,
        active: false,
      },
      {
        bedid: 10003,
        active: true,
      },
      {
        bedid: 10004,
        active: false,
      },
    ],
  },
  {
    roomid: "ROOM A-2",
    active: false,
    rooms: [
      {
        bedid: 10001,
        active: false,
      },
      {
        bedid: 10002,
        active: false,
      },
      {
        bedid: 10003,
        active: false,
      },
      {
        bedid: 10004,
        active: true,
      },
      {
        bedid: 10005,
        active: false,
      },
      {
        bedid: 10006,
        active: false,
      },
    ],
  },
  {
    roomid: "ROOM A-3",
    active: false,
    rooms: [
      {
        bedid: 10001,
        active: false,
      },
      {
        bedid: 10002,
        active: true,
      },
      {
        bedid: 10003,
        active: false,
      },
      {
        bedid: 10004,
        active: false,
      },
      {
        bedid: 10005,
        active: true,
      },
      {
        bedid: 10006,
        active: false,
      },
      {
        bedid: 10007,
        active: false,
      },
      {
        bedid: 10008,
        active: false,
      },
    ],
  },
  {
    roomid: "ROOM A-4",
    active: false,
    rooms: [
      {
        bedid: 10001,
        active: false,
      },
      {
        bedid: 10002,
        active: true,
      },
      {
        bedid: 10003,
        active: false,
      },
      {
        bedid: 10004,
        active: false,
      },
      {
        bedid: 10005,
        active: true,
      },
      {
        bedid: 10006,
        active: false,
      },
    ],
  },
  {
    roomid: "ROOM A-5",
    active: false,
    rooms: [
      {
        bedid: 10001,
        active: false,
      },
      {
        bedid: 10002,
        active: false,
      },
      {
        bedid: 10003,
        active: false,
      },
      {
        bedid: 10004,
        active: true,
      },
    ],
  },
  {
    roomid: "ROOM L-1",
    active: false,
    rooms: [
      {
        bedid: 20001,
        active: false,
      },
      {
        bedid: 20002,
        active: false,
      },
    ],
  },
];
