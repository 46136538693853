import { Card, CardContent, Grid, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import NightShelterOutlinedIcon from "@mui/icons-material/NightShelterOutlined";
import Face2Icon from "@mui/icons-material/Face2";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import { allbiz } from "../service";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { enumReducerType } from "../config/enumReducerTypes";

const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState([]);

  const handleHostelid = (item) => {
    const type = enumReducerType.HOSTEL_ID;
    const payload = item;
    const action = { type, payload };
    dispatch(action);
    sessionStorage.setItem("hostelState", item);
    navigate("/hostelbook");
  };

  const getHostelall = async () => {
    const result = await allbiz();
    setData(result);
  };

  React.useEffect(() => {
    getHostelall();
  }, []);
  return (
    <div
      style={{ height: "100%", minHeight: "87vh" }}
      className="content-align-outside-comp content-height"
    >
      <Grid container spacing={2}>
        <Grid
          item
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
          className="content-flex-center"
        >
          <div>
            <NightShelterOutlinedIcon
              sx={{ fontSize: 110, color: "#00438b" }}
            />
            <Typography
              variant="h3"
              style={{
                marginTop: "-15px",
                color: "#00438b",
              }}
            >
              Hostel Managment System
            </Typography>
            <span style={{ marginLeft: "20px" }} className="loader"></span>
          </div>
        </Grid>
        <Grid item xl={5} lg={5} md={5} sm={12} xs={12}>
          <Grid container spacing={2}>
            {data.slice(0, 4).map((item) => (
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <Card
                  onClick={() => handleHostelid(item.hostelId)}
                  sx={{
                    position: "relative",
                    bgcolor: "#00438b",
                    color: "white",
                    height: "230px",
                  }}
                >
                  <CardContent>
                    {item.name === "Girls Hostel" ? (
                      <Face2Icon
                        sx={{
                          fontSize: "100px",
                          color: "#EBF0F5",
                          opacity: "0.6",
                        }}
                      />
                    ) : (
                      <PersonOutlineIcon
                        sx={{
                          fontSize: "100px",
                          color: "#EBF0F5",
                          opacity: "0.6",
                        }}
                      />
                    )}
                    <Typography
                      variant="h4"
                      style={{
                        position: "absolute",
                        bottom: "20px",
                      }}
                    >
                      {" "}
                      {item.name}{" "}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Home;
