import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  ListItemIcon,
  ListItemButton,
  Box,
  Drawer,
  CssBaseline,
  Toolbar,
  List,
  Typography,
  Divider,
  IconButton,
} from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import MenuIcon from "@mui/icons-material/Menu";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { Link, useNavigate } from "react-router-dom";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import CottageOutlinedIcon from "@mui/icons-material/CottageOutlined";
import EditNoteIcon from "@mui/icons-material/EditNote";
import BubbleChartIcon from "@mui/icons-material/BubbleChart";
import { NavRoutes } from "./NavRoutes";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import HotelOutlinedIcon from "@mui/icons-material/HotelOutlined";

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export const Navbar = () => {
  const navigate = useNavigate();
  const navItems = [
    { name: "Home", icon: <CottageOutlinedIcon /> },
    { name: "facility", icon: <EditNoteIcon /> },
    { name: "hostelbook", icon: <BubbleChartIcon /> },
    { name: "dashboard", icon: <AdminPanelSettingsOutlinedIcon /> },
    { name: "logout", icon: <PowerSettingsNewIcon /> },
  ];

  const [open, setOpen] = React.useState(false);
  const [navhide, setNavhide] = useState(!!sessionStorage.getItem("token"));

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const token = sessionStorage.getItem("token");
    setNavhide(!!token);
    if (!token) {
      navigate("/login");
    }
  }, [navigate]);

  return (
    <React.Fragment>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar sx={{ bgcolor: "#00438b" }} position="fixed" open={open}>
          <Toolbar>
            {navhide && (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{ mr: 2, ...(open && { display: "none" }) }}
              >
                <MenuIcon />
              </IconButton>
            )}

            <Typography variant="h6" noWrap component="div">
              <HotelOutlinedIcon
                fontSize="large"
                sx={{ position: "relative", bottom: "-5px" }}
              />{" "}
              HMS
            </Typography>
          </Toolbar>
        </AppBar>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
          variant="persistent"
          anchor="left"
          open={open}
        >
          <DrawerHeader></DrawerHeader>
          <Divider />
          <List>
            {navItems.map((text, index) => (
              <ListItem onClick={handleDrawerClose} key={text} disablePadding>
                <Link
                  style={{ textDecoration: "none" }}
                  to={text.name == "Home" ? `/` : `/${text.name}`}
                >
                  <ListItemButton>
                    <ListItemIcon>{text.icon}</ListItemIcon>
                    <ListItemText
                      sx={{
                        textDecoration: "none",
                        fontSize: "30px",
                        color: "rgba(0, 0, 0, 0.54)",
                      }}
                      primary={text.name.toUpperCase()}
                    />
                  </ListItemButton>
                </Link>
              </ListItem>
            ))}
          </List>
        </Drawer>
        <Main open={open} onClick={handleDrawerClose}>
          <DrawerHeader />
          <NavRoutes />
        </Main>
      </Box>
    </React.Fragment>
  );
};
