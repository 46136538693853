import { enumReducerType } from "../../config/enumReducerTypes";

export const hostelReducer = (state = { hostelid: "" }, action) => {
  switch (action.type) {
    case enumReducerType.HOSTEL_ID:
      return {
        ...state,
        hostelid: action.payload,
      };
  }

  return state;
};
