import React from "react";
import { Navbar } from "./Components/Navbar";
import { configStore } from "./Store/configStore";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

const App = () => {
  const mystore = configStore();
  return (
    <div style={{ height: "100vh" }}>
      <BrowserRouter>
        <Provider store={mystore}>
          <Navbar />
        </Provider>
      </BrowserRouter>
    </div>
  );
};

export default App;
