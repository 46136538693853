import {
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { roomall } from "../service";
import { useDispatch } from "react-redux";
import { enumReducerType } from "../config/enumReducerTypes";

const HostelBookside = ({ toggle, setlabels, setAllocated, getallbed }) => {
  const disptach = useDispatch();
  const [options, setOoptions] = useState([]);
  const [select, setSelect] = useState("6ce155c425ee48c983129a4cc5d7f212");

  const handleChangedrop = (e) => {
    setSelect(e.value);
    setlabels(e.label);
  };

  const getRoomall = async () => {
    const result = await roomall();
    setOoptions(result);
  };
  const customStyles = {
    menu: (base) => ({
      ...base,
      zIndex: 1000,
    }),
  };
  useEffect(() => {
    const type = enumReducerType.ROOM_BY_BED;
    const payload = select;
    const action = { type, payload };
    disptach(action);
  }, [select, disptach]);

  useEffect(() => {
    getRoomall();
  }, []);
  return (
    <Card
      className={toggle ? "hotelbook-show" : "hotelbook-side"}
      sx={{ height: "100vh", position: "relative" }}
    >
      <CardContent>
        <h2>PICKUPBIZ</h2>
        <span style={{ fontWeight: 700, position: "relative", top: "-20px" }}>
          HOSTEL MANAGMENT
        </span>

        <Select
          onChange={handleChangedrop}
          styles={customStyles}
          options={options}
        />
        <FormControl>
          <FormLabel id="demo-radio-buttons-group-label">Beds filter</FormLabel>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="female"
            name="radio-buttons-group"
          >
            <FormControlLabel
              value="allbeds"
              control={<Radio onChange={() => getallbed()} size="small" />}
              label="All Beds"
            />
            <FormControlLabel
              value="male"
              control={
                <Radio onChange={() => setAllocated(false)} size="small" />
              }
              label="Allocate"
            />
            <FormControlLabel
              value="other"
              control={
                <Radio onChange={() => setAllocated(true)} size="small" />
              }
              label="Allocated"
            />
          </RadioGroup>
        </FormControl>
      </CardContent>
    </Card>
  );
};

export default HostelBookside;
