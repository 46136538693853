import { Button, Card, CardContent, Grid, Typography } from "@mui/material";
import BedSharpIcon from "@mui/icons-material/BedSharp";
import React, { useState, useEffect } from "react";
import HostelBookside from "./HostelBookside";
import { allbedbyroom } from "../service";
import { Allocatebed } from "./Allocatebed";
import MenuIcon from "@mui/icons-material/Menu";
import { useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import AllocatedShow from "./AllocatedShow";

export const HostelBook = () => {
  const roomId = useSelector((state) => state.roombybedReducer.roombybed);
  const [data, setData] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [bedId, setBedId] = useState("");
  const [labels, setlabels] = useState("A-01");
  const [allocated, setAllocated] = useState("");
  const [filtdata, setFiltdata] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [dialogshow, setDialogshow] = useState(false);

  const getallbed = async () => {
    const payload = {
      roomId,
    };
    const result = await allbedbyroom(payload);
    setData(result);
    console.log(result, "-------------");
    setFiltdata(result);
  };

  const handleAllocateClick = (item) => {
    setDialogOpen(true);
    setBedId(item);
  };

  const handleToggle = () => {
    setToggle(!toggle);
  };

  const handleDailogshow = (item) => {
    setDialogshow(true);
    setBedId(item);
  };

  useEffect(() => {
    const filtered = data.filter((item) => item.isAllocated === allocated);
    setFiltdata(filtered);
  }, [allocated]);

  useEffect(() => {
    getallbed();
  }, [roomId]);

  return (
    <div className="content-align-outside-comp content-height">
      <Grid container spacing={2}>
        <Grid item xl={2} lg={2} md={2} sm={12} xs={12}>
          <HostelBookside
            getallbed={getallbed}
            setlabels={setlabels}
            setAllocated={setAllocated}
            toggle={toggle}
          />
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <div className="content-room-book-id">
            <Typography variant="h4">
              {toggle ? (
                <CloseIcon className="menu-icon" onClick={handleToggle} />
              ) : (
                <MenuIcon className="menu-icon" onClick={handleToggle} />
              )}
              ROOM NO - {labels}
            </Typography>
          </div>
          <Grid container spacing={2}>
            {filtdata.map((item) => (
              <Grid item xl={3} lg={3} md={3} sm={6} xs={6}>
                <Card
                  sx={{
                    height: "200px",
                    bgcolor: item.isAllocated ? "#00438b17" : "#00438b",
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <span
                          style={{
                            fontSize: "min(max(40px, 4vw), 65px)",
                            color: item.isAllocated ? "#00438b" : "white",
                          }}
                        >
                          {item.name}
                          <span
                            style={{
                              fontSize: "min(max(22px, 2vw), 20px)",
                            }}
                          >
                            ({item.bedLocation})
                          </span>{" "}
                        </span>
                      </Grid>
                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        {item.isAllocated === false && (
                          <Button
                            endIcon={<BedSharpIcon />}
                            onClick={() => handleAllocateClick(item.bedId)}
                            variant="contained"
                            sx={{ color: "#00438b" }}
                            color="inherit"
                          >
                            ALLOCATE
                          </Button>
                        )}
                      </Grid>
                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        {item.isAllocated === true && (
                          <Button
                            variant="contained"
                            endIcon={<BedSharpIcon />}
                            onClick={() => handleDailogshow(item._id)}
                          >
                            {" "}
                            {item.subsAllocatId}
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>

        <Allocatebed
          bedId={bedId}
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
        />
        <AllocatedShow
          bedId={bedId}
          open={dialogshow}
          onClose={() => setDialogshow(false)}
        />
      </Grid>
    </div>
  );
};
